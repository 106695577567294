export default [
    {
    fileName: "client/Banner_Recomanda.png",
    link:
      "https://upromania.ro/recomanda/?utm_source=uponline&utm_medium=banner&utm_campaign=Recomanda",
    category: "Banner",
    action: "Click",
    label: "Client_Recomanda",
  },
  {
    fileName: "client/Creste-Valoarea-nominala-uponline-1018x166.png",
    link:
        "https://upromania.ro/companii/card-de-masa/",
    category: "Banner",
    action: "Click",
    label: "Companii_VN40",
  },
  {
    fileName: "beneficiary/Cultura_oct_2023.png",
    link:
        "https://upromania.ro/companii/card-cultural/",
    category: "Banner",
    action: "Click",
    label: "companii_upcultura_vn_210",
  },
  {
    fileName: "client/banner-TazzTME.jpg",
    link: "https://companie.upromania.ro/media/ultimele-noutati/up-permite-plata-tazz/",
    category: "Banner",
    action: "Click",
    label: "Companii_TazzTME",
  },

  {
    fileName: "client/banner_gpay.png",
    link: "https://upromania.ro/utilizatori/google-pay/",
    category: "Banner",
    action: "Click",
    label: "Client_GPay",
  },
  {
    fileName: "client/UP_carburant.jpg",
    link:
      "https://upromania.ro/companii/carburant/?utm_source=uponline&utm_medium=banner&utm_campaign=carburant",
    category: "Up Achizitii",
    action: "UpCarburant",
    label: "Client_UpCarburant",
  },
  
  {
    fileName: "client/banner_pay.png",
    link: "https://upromania.ro/utilizatori/up-mobil-pay/",
    category: "Banner",
    action: "Click",
    label: "Client_UpMobilPay",
  },
  {
    fileName: "client/UP_semnatura.jpg",
    link:
      "https://upromania.ro/companii/semnatura/?utm_source=uponline&utm_medium=banner&utm_campaign=semnatura+electronica",
    category: "Up Achizitii",
    action: "UpSemanaturaElectrica",
    label: "Client_UpSemanaturaElectrica",
  },
];
